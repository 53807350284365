<template>
  <div>

    <el-row>
      <el-col :xs="2" :sm="8" :md="8" :lg="8" :xl="8">
        <div style="height: 1px;"></div>
      </el-col>
      <el-col :xs="20" :sm="8" :md="8" :lg="8" :xl="8"  class="">
        <el-row>
          <el-col style="height: 4vh;"></el-col>
          <el-col> <div class="center">

            <el-row>
              <el-col :span="10" style="margin: auto; float: unset" class="lineheight-center">
                <span class="font-weight">在线试用申请:{{title}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2" class="height-1vh"></el-col>
              <el-col :span="20">
                <el-form  ref="formInline" :rules="rules" :model="formInline" class="">
                  <el-form-item label="" prop="companyName">
                    <el-input v-model="formInline.companyName" placeholder="公司名称（必填）"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="name">
                    <el-input v-model="formInline.name" placeholder="您的称呼（必填）"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="phone">
                    <el-input v-model="formInline.phone" placeholder="您的电话（必填）"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="position">
                    <el-input v-model="formInline.position" placeholder="您的职位"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="type">
                    <el-input v-model="formInline.type" placeholder="公司行业"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('formInline')" style="background-color: rgba(22, 155, 213, 1);">立即申请</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                  </el-form-item>
                  <el-form-item>
                    <span class="font-overflow-hidden">点击立即申请即表示你已阅读并同意《<a href="" style="color: #169BD5;">伊登云服务条款</a>》</span>
                  </el-form-item>

                </el-form>
              </el-col>
              <el-col :span="2" class="height-1vh"></el-col>
            </el-row>

          </div></el-col>
          <el-col style="height: 4vh;"></el-col>
        </el-row>
      </el-col>
      <el-col :xs="2" :sm="8" :md="8" :lg="8" :xl="8">
        <div style="height: 1px;"></div>
      </el-col>
    </el-row>

  </div>
</template>

<script>

export default {
  name: "TryApply",
  data(){
    return {
      title:'',
      formInline: {
        companyName: '',
        name: '',
        phone: '',
        position: '',
        type: ''
      },
      homeCarousel: [
        require("assets/img/home.png"),
        require("assets/img/teach_home.png"),
      ],
      officeEnv:require("assets/img/officeEnv/002.png"),
      rules: {
        companyName: [
          { required: true, message: '公司名称', trigger: 'blur' },
          { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '您的称呼', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '您的电话', trigger: 'change' }
        ],
        position: [
          { required: true, message: '您的职位', trigger: 'change' }
        ],
        type: [
          { required: true, message: '公司行业', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      console.log("3333"+formName)
      this.$refs[formName].validate((valid) => {
        console.log("12112")
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      console.log("123");
      console.log(this.$refs[formName]);
      this.$refs[formName].resetFields();
    }
  },
  created:function(){
    if(this.$route.query.title) {
      this.title=this.$route.query.title;
    }
  },
  mounted() {
  }
}

</script>

<style scoped>
* {
    font-size: 16px;
}
.center{
/*  position: absolute;
  left: 0px;
  top: 0px;*/
  width: 100%;
  height: 60vh;
  background: inherit;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.img-relative{position:relative;}
.img-sty{
  font-size: 1em;
  color: #000000;
  position: absolute;
  bottom: 5vw;
  left: 15vw;
  width: 20vw;
  text-align: left;
}

.height-cla{
  height: 50vh;
}

.left-img{
  width: 100%;
}

</style>